import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Platform,
  useWindowDimensions,
  AppState,
} from 'react-native';

import HubConnect from '../../socket/socket';

// context
import { AuthContext } from '../auth/auth.utils';
import { ToasterContext } from '../../toaster/toaster.provider';
import { SocketContext } from '../../socket/socket.provider';

// icons
import BrokenLink from '../../assets/icons/broken-link.svg';
import SettingsIcon from '../../assets/icons/info.svg';

// styles
import styles from './connection.styles';
import mixins, { adaptiveSize } from '../../app/styles';
import Loader from '../../components/Loader/Loader';
import { waitForNetwork } from '../../api/api';
import useAppState from 'react-native-appstate-hook';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import { useAppNavigation, useAppRoute } from '#/navigation/navigation.ref';

const ICON_SETTINGS_WIDTH = adaptiveSize(50);
const ICON_SETTINGS_HEIGHT = adaptiveSize(38);
const HOUR_MS = 60 * 60 * 1000;

type ConnectionState = 'IDLE' | 'CONNECTING' | 'FAILED' | 'CONNECTED';

const ConnectScreen = () => {
  const didRetry = useRef(false);
  const dimensions = useWindowDimensions();
  const didRefreshToken = useRef(false);
  const [appFocused, setAppFocused] = useState(AppState.currentState === 'active');
  const navigation = useAppNavigation();
  const [loggedInAsAdmin, setLoggedInAsAdmin] = useState(false);
  const { accessToken, logout, isAuth, webAuth, refreshWeb, redirect } = useContext(AuthContext);
  const { error } = useContext(ToasterContext);
  const { onJsonPatch, didLoadData, cleanServerData } = useContext(SocketContext);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionState>('IDLE');
  const ICON_SIZE = dimensions.width / 4;
  const route = useAppRoute<'Connect'>();

  useAppState({
    onChange(state: string) {
      if (state.match(/inactive|background/)) {
        setAppFocused(false);
      } else {
        setAppFocused(true);
      }
    },
  });

  useEffect(() => {
    // Delay needed to unmount bottom sheet
    const delayedAction = setTimeout(() => {
      if ((connectionStatus === 'CONNECTED' && didLoadData) || loggedInAsAdmin) {
        if (redirect || route.params?.redirect) {
          navigation.replace(redirect || (route.params.redirect as any));
          return;
        }

        if (Platform.OS !== 'web') {
          navigation.reset({ routes: [{ name: 'Stream' }] });
          return;
        }

        const state = navigation.getState();

        // Params is not present there for some reason, this manually recreates params from path
        const params = Object.fromEntries(
          new URLSearchParams(state.routes[0].path?.split('?')?.[1]).entries(),
        );

        if (state.routes[0]?.name !== 'Connect' && state.routes[0]?.name !== 'Activate') {
          navigation.replace(state.routes[0].name, params);
          return;
        }

        navigation.navigate('Home');
      } else if (connectionStatus === 'CONNECTED' && !HubConnect.isConnected) {
        setConnectionStatus('FAILED');
      }
    }, 100);

    return () => clearTimeout(delayedAction);
  }, [didLoadData, navigation, connectionStatus, redirect, loggedInAsAdmin]);

  useEffect(() => {
    if (!accessToken && !webAuth?.admin) {
      navigation.replace('SignIn');
    }
  }, [accessToken, navigation]);

  const handleConnection = useCallback(async () => {
    try {
      setConnectionStatus('CONNECTING');

      await waitForNetwork();

      if (HubConnect.isConnected) {
        await HubConnect.end();
      }

      await HubConnect.createConnection(onJsonPatch);
      await HubConnect.start();

      setConnectionStatus('CONNECTED');
    } catch (e: any) {
      setConnectionStatus('FAILED');
      if (e?.payload?.code === 401) {
        await logout();
      } else {
        if (!didRetry.current) {
          didRetry.current = true;
          return handleConnection();
        }
        console.error(e);
        error({ message: 'An error occurred. Please try again later' });
      }
    }
  }, [error, logout, onJsonPatch]);

  const shouldRefreshToken =
    webAuth?.refreshToken && (webAuth.expiresAt - Date.now() <= HOUR_MS || route.params?.refresh);

  const refreshWebToken = useCallback(async () => {
    if (!webAuth) {
      return;
    }

    didRefreshToken.current = true;

    try {
      await refreshWeb(webAuth.refreshToken);
    } catch (err) {
      await logout();
    }
  }, [logout, refreshWeb, webAuth]);

  useEffect(() => {
    if (!appFocused) {
      return;
    }

    if (!didRefreshToken.current && shouldRefreshToken) {
      refreshWebToken();

      return;
    }

    if (route.params?.logout) {
      logout();

      return;
    }

    if (webAuth?.admin && HubConnect.currentlyConnectedUserId === -1) {
      setLoggedInAsAdmin(true);

      return;
    }

    if (connectionStatus === 'IDLE' && isAuth) {
      if (didLoadData) {
        cleanServerData();
      }
      handleConnection();
    }
  }, [
    cleanServerData,
    connectionStatus,
    didLoadData,
    handleConnection,
    isAuth,
    refreshWebToken,
    webAuth,
    appFocused,
    shouldRefreshToken,
  ]);

  return (
    <View style={styles.container}>
      {Platform.OS !== 'web' && connectionStatus !== 'CONNECTED' && (
        <TouchableOpacity
          style={styles.settingsIcon}
          activeOpacity={0.9}
          onPress={() => navigation.navigate('Settings')}
        >
          <SettingsIcon
            width={ICON_SETTINGS_WIDTH}
            height={ICON_SETTINGS_HEIGHT}
            fill={mixins.color.white}
          />
        </TouchableOpacity>
      )}
      <View style={styles.containerWrap}>
        {connectionStatus === 'FAILED' ? (
          <View style={styles.errorConnectionWrap}>
            <BrokenLink style={styles.icon} width={ICON_SIZE} height={ICON_SIZE} />
            <Text style={styles.errorConnectionText}>Oops! Connection failed :(</Text>
            <TouchableOpacity
              style={styles.btnLogin}
              activeOpacity={0.9}
              onPress={handleConnection}
            >
              <Text style={styles.btnText}>Retry connection</Text>
            </TouchableOpacity>
          </View>
        ) : null}
        {connectionStatus !== 'FAILED' ? (
          <>
            <Loader size={80} color={mixins.color.blueLightest} style={styles.loader} />
            <Text style={styles.connectingText}>Connecting...</Text>
          </>
        ) : null}
      </View>
      {connectionStatus !== 'CONNECTED' && <BottomSheet />}
    </View>
  );
};

export default ConnectScreen;
