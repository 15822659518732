import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  scrollView: {
    padding: 10,
  },
  header: {
    color: mixins.color.whiteText,
    fontSize: mixins.font.modalFont,
    marginBottom: mixins.indent.i2,
    textTransform: 'uppercase',
  },
  deviceList: { gap: 10 },
  offlineDeviceText: {
    color: mixins.color.whiteText,
    backgroundColor: '#15161a',
    padding: 8,
    flexGrow: 1,
    borderRadius: 5,
  },
  device: {
    flexDirection: 'row',
    gap: 10,
    paddingHorizontal: 10,
    overflow: 'hidden',
  },
  deviceNameWrapper: { width: 120, flexShrink: 0, justifyContent: 'center' },
  deviceName: { color: mixins.color.grayLightest, textAlign: 'right', lineHeight: 16 },
  deviceDetails: { flexDirection: 'row', gap: 5, flexWrap: 'wrap', flex: 1 },
  deviceStatus: {
    color: mixins.color.whiteText,
    backgroundColor: '#15161a',
    padding: 8,
    paddingHorizontal: 16,
    flexGrow: 1,
    borderRadius: 5,
  },
  deviceCamera: {
    backgroundColor: '#282930',
    padding: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    flexGrow: 1,
    flexDirection: 'row',
  },
  deviceCameraActive: {
    backgroundColor: mixins.color.blue,
  },
  deviceCameraNameCentered: { color: mixins.color.whiteText, textAlign: 'center' },
  deviceCameraName: { color: mixins.color.whiteText },
  deviceBitrate: {
    color: mixins.color.grayDarker,
    marginLeft: 'auto',
  },
  deviceBitrateActive: {
    color: mixins.color.whiteText,
  },


  recordBox: {
    paddingTop: 15,
    paddingLeft: 140,
    paddingRight: 10,
    flexDirection: 'row',
  },
  recordButton: {
    backgroundColor: mixins.color.redDark,
    borderColor: mixins.color.red,
    gap: 10,
  },
  recordButtonStop: {
    backgroundColor: '#282930',
    gap: 0,
    height: 40,
  },
  recordStart: {
    width: 16,
    height: 16,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: mixins.color.whiteText,
    borderRadius: 100,
    backgroundColor: mixins.color.red,
  },
  recordStop: {
    backgroundColor: mixins.color.red,
    width: 12,
    height: 12,
  },
  recordButtonTextTop: {
    color: mixins.color.whiteText,
    lineHeight: 16,
  },
  recordButtonTextBottom: {
    color: mixins.color.whiteText,
    fontSize: 10,
    lineHeight: 12,
  },
});

export default styles;
