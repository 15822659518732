import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Image, Platform, Pressable, Text, useWindowDimensions, View } from 'react-native';
import { Channel, ChannelState, Settings, Target } from '../../socket/document.type';
import type { SendPatch } from '../../socket/socket.type';

import CloseIcon from '../../assets/icons/close.svg';
import SettingsIcon from '../../assets/icons/settings-filled.svg';
import PlayIcon from '../../assets/icons/play-circle.svg';
import CogsIcon from '../../assets/icons/cogs.svg';
import StopIcon from '../../assets/icons/stop-circle.svg';
import TranscoderIcon from '../../assets/icons/plus-minus.svg';

import styles from './ChannelToggle.styles';
import mixins from '../../app/styles';
import handleLink from '../../helpers/handle-link';
import { validateRMTPUrl } from '../../helpers/validators';
import Loader from '../../components/Loader/Loader';
import PressableWithOpacityHover from '../../components/PressableWithOpacityHover';
import Tooltip from '../../components/Tooltip';
import { CustomTarget } from '../../helpers/defaultData';
import EditChannelModal from '../EditChannelModal/EditChannelModal';
import { getDataFromChannelAndTarget } from './ChannelToggle.utils';
import { useManageBoxControls } from '#/screens/stream/manageBox/utils';
import { useChannelStatus } from '#/components/ChannelToggle/utils';
import { deleteChannelAction, updateChannelAction } from '#/socket/socket.actions';
import { useChatMessagesStore } from '#/store/chatMessages/chatMessagesStore';
import { SocketContext } from '#/socket/socket.provider';

interface Props {
  id: string;
  data: Channel;
  sendPatch: SendPatch;
  target?: Target;
  handleToggleState: (channel: string) => void;
  handleShowTranscoding?: () => void;
  settings: Settings;
  maxInput: string;
  transcodingEnabled: boolean;
  maxActiveChannelsReached: boolean;
  maxActiveChannels: number;
  isStreaming: boolean;
  mobile?: boolean;
  displayChatBadge?: boolean;
}

const ChannelToggle: React.FC<Props> = React.memo(
  ({
    id,
    data,
    sendPatch,
    target = CustomTarget,
    handleShowTranscoding,
    handleToggleState,
    isStreaming,
    maxActiveChannelsReached,
    maxActiveChannels,
    mobile = true,
    displayChatBadge = false,
  }) => {
    const dimensions = useWindowDimensions();
    const unreadMessages = useChatMessagesStore(
      store => store.chatsData[data.TargetId]?.unreadMessages,
    );
    const unseenTips = useChatMessagesStore(store => store.chatsData[data.TargetId]?.unseenTips);
    const hideFullMessageTimeout = useRef<number>();
    const [isModalVisible, setModalVisibility] = useState(false);
    const [blockMessage, setBlockMessage] = useState('');
    const controls = useManageBoxControls();
    const message = useChannelStatus(id);
    const { serverData } = useContext(SocketContext);

    const handleDelete = () => {
      sendPatch(deleteChannelAction(id));
    };

    const displayBlockMessage = (text = '') => {
      setBlockMessage(text);
      hideFullMessageTimeout.current = setTimeout(() => {
        setBlockMessage('');
      }, 6000) as unknown as number;
    };

    const toggleState = () => {
      if (!data.IsOn && message.status === 'error') {
        return displayBlockMessage(message.message);
      }

      if (!data.IsOn && maxActiveChannelsReached) {
        return displayBlockMessage(
          `Your plan does not allow more than ${maxActiveChannels} channels`,
        );
      }

      handleToggleState(id);
    };

    const toggleModalVisibility = () => {
      setModalVisibility(visibility => !visibility);
    };

    const handleChange = (value: Partial<Channel>) => {
      sendPatch(
        updateChannelAction(id, {
          ...value,
          RtmpUrl: value.RtmpUrl === target.DefaultRtmpUrl ? undefined : value.RtmpUrl,
          Name: value.Name === target.Name ? undefined : value.Name,
          TargetId: value.TargetId === 'custom' ? undefined : value.TargetId,
        }),
      );
      toggleModalVisibility();
    };

    useEffect(() => {
      return () => {
        if (hideFullMessageTimeout.current) {
          clearTimeout(hideFullMessageTimeout.current);
        }
      };
    }, []);

    if (!target) {
      return null;
    }

    const isCustom = target.Id === 'custom';
    const isLandscape = dimensions.width > dimensions.height;

    const onMobileClick = () => {
      const chatStarted = Object.values(serverData.Platforms.Chats).find(
        c => c.TargetId === target.Id,
      );

      const targetTab = controls.channelScreen === 'CHATS' && chatStarted ? 'CHATS' : 'SETTINGS';

      controls.editChannel(id, targetTab);
    };

    if (mobile && Platform.OS === 'web') {
      return (
        <PressableWithOpacityHover onPress={toggleState} style={styles.mobileWebOuterWrapper}>
          <View
            style={[
              styles.editChannel,
              styles.editChannelMobileWeb,
              { borderColor: message.color },
            ]}
          >
            {message.message ? (
              <Text
                style={[
                  styles.statusText,
                  { backgroundColor: message.color, color: message.textColor },
                ]}
              >
                {message.message}
              </Text>
            ) : null}
            {data.IsOn && (data.State === ChannelState.Idle || !data.State) && isStreaming ? (
              <Loader color={mixins.color.blueLightest} />
            ) : isCustom ? (
              <CogsIcon style={styles.mobileCustomChannelImage} fill={mixins.color.white} />
            ) : (
              <Image
                style={styles.mobileChannelImage}
                source={{
                  uri: `https://de10.streamster.io:6001/Files/targets/${target.Id}.png`,
                }}
              />
            )}
          </View>
          {blockMessage ? (
            <View style={styles.blockMessage}>
              <Text style={styles.blockMessageDescription}>{blockMessage}</Text>
            </View>
          ) : null}
        </PressableWithOpacityHover>
      );
    }

    if (mobile) {
      return (
        <PressableWithOpacityHover
          onPress={onMobileClick}
          style={isLandscape ? styles.mobileOuterLandscape : styles.mobileOuterWrapper}
        >
          <View
            style={[
              isLandscape ? styles.mobileOpenedStatusLandscape : styles.mobileOpenedStatus,
              controls.channelToEdit === id && styles.mobileOpenedStatusActive,
            ]}
          />
          <View style={[styles.editChannel, styles.editChannelMobile]}>
            {data.IsOn && (data.State === ChannelState.Idle || !data.State) && isStreaming ? (
              <Loader color={mixins.color.blueLightest} />
            ) : isCustom ? (
              <CogsIcon style={styles.mobileCustomChannelImage} fill={mixins.color.white} />
            ) : (
              <Image
                style={styles.mobileChannelImage}
                source={{
                  uri: `https://de10.streamster.io:6001/Files/targets/${target.Id}.png`,
                }}
              />
            )}
          </View>
          <View
            style={[
              isLandscape ? styles.mobileBadgeStatusLandscape : styles.mobileBadgeStatus,
              { backgroundColor: message.statusColor || message.color },
            ]}
          />
          {blockMessage ? (
            <View style={styles.blockMessage}>
              <Text style={styles.blockMessageDescription}>{blockMessage}</Text>
            </View>
          ) : null}
          {displayChatBadge && (unreadMessages > 0 || unseenTips > 0) && (
            <View style={[styles.badgeWrapper, unseenTips > 0 && styles.badgeWrapperRed]}>
              <Text style={styles.badgeText}>{unreadMessages + unseenTips}</Text>
            </View>
          )}
        </PressableWithOpacityHover>
      );
    }

    return (
      <>
        <View style={styles.editChannel}>
          <Tooltip text="Click to open web shortcut.\nThis can be configured in channel settings.">
            <View style={styles.imageWrapper}>
              <Pressable
                style={({ hovered }) => ({
                  transitionDuration: '0.25s',
                  transform: [{ scale: hovered ? 1.1 : 1 }],
                })}
                onPress={() => handleLink(data.WebUrl || target.WebUrl, '_blank')}
              >
                {isCustom ? (
                  <CogsIcon style={styles.customChannelImage} fill={mixins.color.white} />
                ) : (
                  <Image
                    style={styles.channelImage}
                    source={{
                      uri: `https://de10.streamster.io:6001/Files/targets/${target.Id}.png`,
                    }}
                  />
                )}
              </Pressable>
            </View>
          </Tooltip>
          <View style={styles.details}>
            <PressableWithOpacityHover onPress={handleDelete} style={styles.removeIcon}>
              <Tooltip text="Delete channel">
                <CloseIcon width={20} fill={mixins.color.white} />
              </Tooltip>
            </PressableWithOpacityHover>
            <Text style={styles.channelName} numberOfLines={1}>
              {data.Name || target.Name}
            </Text>
            <View style={styles.buttonRow}>
              {handleShowTranscoding && (
                <PressableWithOpacityHover onPress={handleShowTranscoding}>
                  <Tooltip text="Open transcoder settings">
                    <TranscoderIcon
                      width={28}
                      fill={data.TranscoderId ? mixins.color.greenLight : mixins.color.white}
                    />
                  </Tooltip>
                </PressableWithOpacityHover>
              )}
              <PressableWithOpacityHover onPress={toggleModalVisibility}>
                <Tooltip text="Change your channel settings">
                  <SettingsIcon width={24} fill={mixins.color.white} />
                </Tooltip>
              </PressableWithOpacityHover>
              {data.IsOn && (data.State === ChannelState.Idle || !data.State) && isStreaming ? (
                <Loader style={styles.toggleLoading} size="small" />
              ) : (
                <PressableWithOpacityHover onPress={toggleState}>
                  <View style={styles.toggleButton}>
                    {data.IsOn ? (
                      <Tooltip text="Stop restreaming">
                        <StopIcon width={30} fill={mixins.color.purple} />
                      </Tooltip>
                    ) : (
                      <Tooltip text="Start restreaming to the platform">
                        <PlayIcon width={30} fill={mixins.color.greenPlay} />
                      </Tooltip>
                    )}
                  </View>
                </PressableWithOpacityHover>
              )}
            </View>
            {data.State === ChannelState.RunningOk && (
              <Text style={styles.bitrate}>{data.Bitrate || 0} Kb/s</Text>
            )}
          </View>
          {message.message ? (
            <View
              style={[
                styles.statusText,
                {
                  backgroundColor: message.color,
                  //@ts-ignore
                  color: message.textColor,
                },
              ]}
            >
              <Tooltip text={message.tooltip}>{message.message}</Tooltip>
            </View>
          ) : null}
          {blockMessage ? (
            <View style={styles.blockMessage}>
              <Text style={styles.blockMessageTitle}>Restreaming not possible:</Text>
              <Text style={styles.blockMessageDescription}>{blockMessage}</Text>
            </View>
          ) : null}
        </View>
        {isModalVisible && (
          <EditChannelModal
            channel={getDataFromChannelAndTarget({ channel: data, target })}
            handleDismiss={toggleModalVisibility}
            handleSave={handleChange}
          />
        )}
      </>
    );
  },
);

export default ChannelToggle;
