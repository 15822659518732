import { chatDataFixture } from '#/fixtures/chatDataFixture';
import HubConnect from '#/socket/socket';
import { NewChatsEvent, PlatformChat, Store } from '#/store/chatMessages/chatMessagesStoreTypes';
import { createZustandStore } from '#/utils/zustand';

export const getInitialPlatformChat = (): PlatformChat => ({
  authors: {},
  messages: [],
  unreadMessages: 0,
  unseenTips: 0,
});

export const useChatMessagesStore = createZustandStore(
  'ChatMessages',
  {
    chatsData: {},
  } as Store,
  (set, get) => ({
    sendChatMessage(chatId: string, message: string, toUserId?: string) {
      HubConnect.sendChatMessage(chatId, message, toUserId);
    },

    setReplyTo(authorId: string | undefined) {
      set(state => {
        state.replyTo = authorId;
      });
    },

    clearUnreadMessages(targetId: string) {
      set(state => {
        if (state.chatsData[targetId]) {
          state.chatsData[targetId].unreadMessages = 0;
          state.chatsData[targetId].unseenTips = 0;
        }
      });
    },

    clearAllUnreadMessages() {
      set(state => {
        Object.keys(state.chatsData).forEach(targetId => {
          state.chatsData[targetId].unreadMessages = 0;
          state.chatsData[targetId].unseenTips = 0;
        });
      });
    },

    onReceiveChatMessages(message: NewChatsEvent) {
      set(state => {
        message.Targets.forEach(target => {
          if (!state.chatsData[target.TargetId]) {
            state.chatsData[target.TargetId] = getInitialPlatformChat();
          }

          const chat = state.chatsData[target.TargetId];

          const lastMessages = chat.messages.slice(-10);

          target.Messages.forEach(message => {
            const exisingMessage = lastMessages.find(
              m => m.Msg === message.Msg && m.Time.getTime() === message.Time.getTime(),
            );

            const msg = { ...message };

            if (msg.Time.getTime() < 0) {
              msg.Time = new Date();
            }

            if (!exisingMessage) {
              chat.messages.push(msg);

              if (message.Tip) {
                chat.unseenTips++;
              } else {
                chat.unreadMessages++;
              }
            }
          });

          target.Authors.forEach(author => {
            chat.authors[author.Id] = author;
          });
        });
      });
    },
  }),
);
