import { Platform, StyleSheet } from 'react-native';
import mixins from '../../../app/styles';

const WIDTH = Platform.OS === 'web' ? 130 : 120;
const HEIGHT = Platform.OS === 'web' ? 150 : 130;

const styles = StyleSheet.create({
  imageWrapper: { padding: 5, flexGrow: 1, justifyContent: 'center', alignItems: 'center' },
  channel: {
    display: 'flex',
    backgroundColor: '#303030',
    width: WIDTH,
    height: HEIGHT,
    transitionDuration: '.125s',
    transitionProperty: 'backgroundColor',
  },
  channelHover: {
    backgroundColor: '#293c50',
  },
  channelImage: {
    flexGrow: 1,
    width: '100%',
    objectFit: 'contain',
  },
  channelNameWrapper: {
    backgroundColor: '#404040',
    justifyContent: 'center',
    paddingVertical: 5,
  },
  channelName: {
    fontSize: mixins.font.bigFont,
    color: mixins.color.whiteText,
    textAlign: 'center',
  },
});

export default styles;
